/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
import anime from 'animejs';

export const useMicroInteractionData = () => {
  const moon = 'M1,16.7736C1.35855,31.7163,20.709,36.0371,28,24.1764C16.9879,30.5417,9.67343,26.1502,7.42043,19.5503C5.02922,12.5457,8.33948,3.05345,18.9816,1.29391C9.42143,-0.509699,1.00001,6.15155,1,16.7736Z';
  const star1 = 'M17.5,8L18.0902,9.90983H20L18.4549,11.0902L19.0451,13L17.5,11.8197L15.9549,13L16.5451,11.0902L15,9.90983H16.9098L17.5,8Z';
  const star1Hide = 'M4,10L4.23607,10.7639H5L4.38197,11.2361L4.61803,12L4,11.5279L3.38197,12L3.61803,11.2361L3,10.7639H3.76393L4,10Z';
  const star2 = 'M12.5,17L13.0902,18.9098H15L13.4549,20.0902L14.0451,22L12.5,20.8197L10.9549,22L11.5451,20.0902L10,18.9098H11.9098L12.5,17Z';
  const star2Hide = 'M6.5,22L6.8541,23.1459H8L7.07295,23.8541L7.42705,25L6.5,24.2918L5.57295,25L5.92705,23.8541L5,23.1459H6.1459L6.5,22Z';
  const star3 = 'M22.5,17L23.0902,18.9098H25L23.4549,20.0902L24.0451,22L22.5,20.8197L20.9549,22L21.5451,20.0902L20,18.9098H21.9098L22.5,17Z';
  const star3Hide = 'M11,27L11.2361,27.7639H12L11.382,28.2361L11.618,29L11,28.5279L10.382,29L10.618,28.2361L10,27.7639H10.7639L11,27Z';

  const sun = 'M78.8636,16C78.8636,24.2843,72.1479,31,63.8636,31C55.5794,31,48.8636,24.2843,48.8636,16C48.8636,7.71573,55.5794,1,63.8636,1C72.1479,1,78.8636,7.71573,78.8636,16Z';
  const ray1 = 'M46.3258,2.64395L46.8801,3.03203L48.6736,4.28789L48.2412,4.90553L47.3455,4.27837L45.8934,3.26158L44.296,2.14311L43.5456,1.61763L43.978,1L45.7716,2.25586L46.3258,2.64395Z';
  const ray1Hide = 'M53.7803,9.64395L54.3345,10.032L56.1281,11.2879L55.6956,11.9055L54.8,11.2784L53.3478,10.2616L51.7505,9.14311L51,8.61763L51.4325,8L53.2261,9.25586L53.7803,9.64395Z';
  const ray2 = 'M42.8661,15.8909L43.5427,15.8909L45.7323,15.8909V16.6449L44.6389,16.6449L42.8661,16.6449H40.9161H40V15.8909L42.1895,15.8909L42.8661,15.8909Z';
  const ray2Hide = 'M51.8661,16L52.5427,16L54.7323,16V16.754L53.6389,16.754L51.8661,16.754H49.9161H49V16L51.1895,16L51.8661,16Z';
  const ray3 = 'M45.8932,28.8803L46.4475,28.4922L48.241,27.2363L48.6735,27.854L47.7778,28.4811L46.3257,29.4979L44.7283,30.6164L43.9779,31.1419L43.5454,30.5242L45.339,29.2684L45.8932,28.8803Z';
  const ray3Hide = 'M53.3478,21.644L53.902,21.2559L55.6956,20L56.1281,20.6176L55.2324,21.2448L53.7803,22.2616L52.1829,23.3801L51.4325,23.9055L51,23.2879L52.7936,22.032L53.3478,21.644Z';

  const hiddenStar1 = {
    targets: '#path1',
    d: star1Hide,
    opacity: 0,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const hiddenStar2 = {
    targets: '#path2',
    d: star2Hide,
    opacity: 0,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const hiddenStar3 = {
    targets: '#path3',
    d: star3Hide,
    opacity: 0,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const hiddenRay1 = {
    targets: '#path1',
    d: ray1Hide,
    opacity: 0,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const hiddenRay2 = {
    targets: '#path2',
    d: ray2Hide,
    opacity: 0,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const hiddenRay3 = {
    targets: '#path3',
    d: ray3Hide,
    opacity: 0,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const appearRay1 = {
    targets: '#path1',
    d: ray1,
    opacity: 1,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const appearRay2 = {
    targets: '#path2',
    d: ray2,
    opacity: 1,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const appearRay3 = {
    targets: '#path3',
    d: ray3,
    opacity: 1,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const appearStar1 = {
    targets: '#path1',
    d: star1,
    opacity: 1,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const appearStar2 = {
    targets: '#path2',
    d: star2,
    opacity: 1,
    easing: 'easeOutQuad',
    duration: 50,
  };

  const appearStar3 = {
    targets: '#path3',
    d: star3,
    opacity: 1,
    easing: 'easeOutQuad',
    duration: 50,
  };

  return {
    moon,
    star1,
    star2,
    star3,
    sun,
    ray1,
    ray2,
    ray3,
    hiddenStar1,
    hiddenStar2,
    hiddenStar3,
    hiddenRay1,
    hiddenRay2,
    hiddenRay3,
    appearRay1,
    appearRay2,
    appearRay3,
    appearStar1,
    appearStar2,
    appearStar3,
  };
};
