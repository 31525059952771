/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import './index.css';
import { animateToDark, animateToLight } from './script';

export function Switch({ theme, switchTheme }) {
  const moon = 'M1,16.7736C1.35855,31.7163,20.709,36.0371,28,24.1764C16.9879,30.5417,9.67343,26.1502,7.42043,19.5503C5.02922,12.5457,8.33948,3.05345,18.9816,1.29391C9.42143,-0.509699,1.00001,6.15155,1,16.7736Z';
  const star1 = 'M17.5,8L18.0902,9.90983H20L18.4549,11.0902L19.0451,13L17.5,11.8197L15.9549,13L16.5451,11.0902L15,9.90983H16.9098L17.5,8Z';
  const star2 = 'M12.5,17L13.0902,18.9098H15L13.4549,20.0902L14.0451,22L12.5,20.8197L10.9549,22L11.5451,20.0902L10,18.9098H11.9098L12.5,17Z';
  const star3 = 'M22.5,17L23.0902,18.9098H25L23.4549,20.0902L24.0451,22L22.5,20.8197L20.9549,22L21.5451,20.0902L20,18.9098H21.9098L22.5,17Z';

  useEffect(() => {
    if (theme === 'light') {
      animateToLight(switchTheme);
    }
    if (theme === 'dark') {
      animateToDark(switchTheme);
    }
  }, []);
  return (
    <svg className="w-15 h-6 cursor-pointer md:h-8 md:w-20" onClick={() => (theme === 'light' ? animateToDark(switchTheme) : animateToLight(switchTheme))} viewBox="0 0 80 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect id="background" width="80" height="32" rx="16" fill="#1E3A8A" />
      <path id="shape" d={moon} fill="#FEF08A" />
      <path id="path1" d={star1} fill="#FEF08A" />
      <path id="path2" d={star2} fill="#FEF08A" />
      <path id="path3" d={star3} fill="#FEF08A" />
    </svg>
  );
}

Switch.propTypes = {
  theme: PropTypes.string,
  switchTheme: PropTypes.func,
};

Switch.defaultProps = {
  theme: 'light',
  switchTheme: () => {},
};
