/* eslint-disable quotes */
import React from "react";
import { PropTypes } from "prop-types";

export function ContactCard({
  icon,
  title,
  description,
  action,
  bgColor,
  borderColor,
  textColor,
}) {
  return (
    <div className="container z-10 mx-auto flex max-w-sm flex-col rounded-xl bg-slate-50 p-6 shadow-light dark:bg-slate-800 dark:shadow-none">
      <div
        className={`mx-auto flex items-center justify-center rounded-xl ${bgColor.light} ${bgColor.dark} px-24 py-6`}
      >
        <img src={`icons/footer/${icon}`} alt="" />
      </div>
      <span className="py-6 text-center text-2xl font-bold">{title}</span>
      <div className="z-20 w-full rounded-lg border border-solid border-slate-200 bg-slate-200 dark:border-slate-600 dark:bg-slate-600" />
      <span className="py-6 text-center text-base font-bold text-slate-400">
        {description}
      </span>
      <a
        href={action.href}
        className={`container mx-auto flex items-center justify-center rounded-xl border-2 border-solid ${borderColor.light}`}
      >
        <button
          type="button"
          className={`h-full w-full px-5 py-[10px] text-base font-bold ${textColor.light}`}
        >
          {action.title}
        </button>
      </a>
    </div>
  );
}

ContactCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
  }),
  bgColor: PropTypes.shape({
    light: PropTypes.string,
    dark: PropTypes.string,
  }),
  borderColor: PropTypes.shape({
    light: PropTypes.string,
    dark: PropTypes.string,
  }),
  textColor: PropTypes.shape({
    light: PropTypes.string,
    dark: PropTypes.string,
  }),
};
ContactCard.defaultProps = {
  icon: "",
  title: "",
  description: "",
  action: {},
  bgColor: {},
  borderColor: {},
  textColor: {},
};
