import { badges } from '../types/badges';

const prefix = 'https://github.com/Bonny79';

export const portfolioContent = [
  {
    id: 1,
    title: 'Microinteraction',
    badges: [badges.design, badges.frontend],
    src: 'portfolio/simple-microinteraction.png',
    href: `${prefix}/simple-microinteraction`,
    description:
      'Creating a microinteraction starting from Figma design base to the code facts.',
  },
  {
    id: 2,
    title: 'Advanced microinteraction',
    badges: [badges.frontend],
    src: 'portfolio/advanced-microinteraction.png',
    href: `${prefix}/advanced-microinteraction`,
    description:
      'Creating advanced microinteraction with Lottie and React in a easier way than manipulating SVGs.',
  },
  {
    id: 3,
    title: 'Chart live data',
    badges: [badges.frontend, badges.backend, badges.design],
    src: 'portfolio/chart-live-data.png',
    href: `${prefix}/chart-live-data`,
    description:
      'Create live charts with random real time data. D3.js is a library for manipulating documents, based on data. Here we are going to create a live chart data application using socket.io and nodejs to create the server side and reactjs with d3.js will compose the client side.',
  },
  {
    id: 4,
    title: 'React custom template',
    badges: [badges.frontend],
    src: 'portfolio/react-custom-template.png',
    href: `${prefix}/react-custom-template`,
    description:
      'If you are looking to speed up the process of creating new react projects with the same structure, maybe you need to have a look at the react custom template.',
  },
].reverse();
