import React from 'react';
import anime from 'animejs';
import { PropTypes } from 'prop-types';

export const handlePath = (sidebar) => {
  const paths = {
    off: {
      path4: 'M1.7832 2.16943H23.737',
      path5: 'M3 26L32 2',
      path6: 'M3 2L32 26',
    },
    on: {
      path4: 'M2 26H30.7857',
      path5: 'M2 14H33',
      path6: 'M2 2H24.1429',
    },
  };
  return anime.timeline({
    targets: '#path4',
    d: sidebar ? paths.on.path4 : paths.off.path4,
    easing: 'easeInOutQuad',
    duration: 200,
    opacity: sidebar ? 1 : 0,
  }).add({
    targets: '#path5',
    d: sidebar ? paths.on.path5 : paths.off.path5,
    easing: 'easeInOutQuad',
    duration: 200,
  }, 0).add({
    targets: '#path6',
    d: sidebar ? paths.on.path6 : paths.off.path6,
    easing: 'easeInOutQuad',
    duration: 200,
  }, 0);
};

export function Hamburger({ sidebar, setSidebar }) {
  const paths = {
    on: {
      path4: 'M1.7832 2.16943H23.737',
      path5: 'M3 26L32 2',
      path6: 'M3 2L32 26',
    },
    off: {
      path4: 'M2 26H30.7857',
      path5: 'M2 14H33',
      path6: 'M2 2H24.1429',
    },
  };

  return (
    <svg
      id="hamburger"
      viewBox="0 0 35 31"
      width="35"
      height="31"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSidebar(sidebar ? 0 : 1);
        handlePath(sidebar);
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="stroke-slate-800 dark:stroke-slate-50" id="path4" d={`${paths.off.path4}`} strokeWidth="3.29307" strokeLinecap="round" />
      <path className="stroke-slate-800 dark:stroke-slate-50" id="path5" d={`${paths.off.path5}`} strokeWidth="3.29307" strokeLinecap="round" />
      <path className="stroke-slate-800 dark:stroke-slate-50" id="path6" d={`${paths.off.path6}`} strokeWidth="3.29307" strokeLinecap="round" />
    </svg>
  );
}

Hamburger.propTypes = {
  sidebar: PropTypes.number,
  setSidebar: PropTypes.func,
};

Hamburger.defaultProps = {
  sidebar: 0,
  setSidebar: () => {},
};
