/* eslint-disable quotes */
export const experiences = [
  {
    title: 'Frontend',
    description:
      'ReactJS, GraphQL, Webpack, Javascript, TailwindCSS, HTML and CSS.',
    icon: 'frontend.svg',
    stars: 5,
    color: 'sky',
  },
  {
    title: 'Mobile Applications',
    description: 'Java/Swift respectively for Android/iOS.',
    icon: 'mobile.svg',
    stars: 3,
    color: 'emerald',
  },
  {
    title: 'Backend',
    description: 'C#/.NET and NodeJS/ExpressJS.',
    icon: 'backend.svg',
    stars: 3,
    color: 'orange',
  },
  {
    title: 'Cloud',
    description:
      'AZ-204 certified, Azure, microservices, web apps, storage, databases, registries.',
    icon: 'cloud.svg',
    stars: 4,
    color: 'sky',
  },
  {
    title: 'Infrastructure',
    description: 'Docker/Kubernetes basic commands to test pre-deployment.',
    icon: 'infrastructure.svg',
    stars: 2,
    color: 'emerald',
  },
  {
    title: 'DevOps',
    description: 'Git, Agile/Scrum, CI/CD, NPM, Azure',
    icon: 'devOps.svg',
    stars: 4,
    color: 'orange',
  },
];
