import { useEffect, useState } from 'react';
import useMediaQuery from './useMediaQuery';

export const useSlider = () => {
  const desktop = useMediaQuery('(min-width: 1024px)');
  const tablet = useMediaQuery('(max-width: 1023px)');
  const phone = useMediaQuery('(max-width: 768px)');
  const [slides, setSlides] = useState();

  useEffect(() => {
    if (desktop) {
      setSlides(3);
    }
    if (tablet) {
      setSlides(2);
    }
    if (phone) {
      setSlides(1);
    }
  }, [desktop, tablet, phone]);

  return { slides, phone };
};
