import anime from 'animejs';
import { useMicroInteractionData } from '../../hooks/useMicroInteraction';

export const animateToLight = (switchTheme) => {
  switchTheme('light');
  document.documentElement.classList.remove('dark');
  const {
    hiddenStar1,
    hiddenStar2,
    hiddenStar3,
    hiddenRay1,
    hiddenRay2,
    hiddenRay3,
    appearRay1,
    appearRay2,
    appearRay3,
    sun,
  } = useMicroInteractionData();
  anime.timeline({})
    .add(hiddenStar1, 0)
    .add(hiddenStar2, 0)
    .add(hiddenStar3, 0)
    .add({
      targets: '#shape',
      d: sun,
      easing: 'easeInOutQuad',
      duration: 150,
    }, '+=1')
    .add(hiddenRay1, '+=1')
    .add(hiddenRay2, '-=1')
    .add(hiddenRay3, '-=1')
    .add(appearRay1, '+=1')
    .add(appearRay2, '+=0')
    .add(appearRay3, '+=0')
    .add({
      targets: '#background',
      fill: '#38BDF8',
      easing: 'easeInOutQuad',
      duration: 100,
    }, 0);
};

export const animateToDark = (switchTheme) => {
  switchTheme('dark');
  document.documentElement.classList.add('dark');
  const {
    hiddenStar1,
    hiddenStar2,
    hiddenStar3,
    hiddenRay1,
    hiddenRay2,
    hiddenRay3,
    appearStar1,
    appearStar2,
    appearStar3,
    moon,
  } = useMicroInteractionData();
  return anime.timeline({})
    .add(hiddenRay3, 0)
    .add(hiddenRay2, 0)
    .add(hiddenRay1, 0)
    .add({
      targets: '#shape',
      d: moon,
      easing: 'easeInOutQuad',
      duration: 150,
    }, '+=1')
    .add(hiddenStar1, '+=1')
    .add(hiddenStar2, '-=1')
    .add(hiddenStar3, '-=1')
    .add(appearStar1, '+=1')
    .add(appearStar2, '+=0')
    .add(appearStar3, '+=0')
    .add({
      targets: '#background',
      fill: '#1E3A8A',
      easing: 'easeInOutQuad',
      duration: 100,
    }, 0);
};
