import { colors } from './colors';

export const badges = {
  design: {
    title: 'Design',
    color: colors.emerald,
  },
  frontend: {
    title: 'Frontend',
    color: colors.sky,
  },
  backend: {
    title: 'Backend',
    color: colors.orange,
  },
  infrastructure: {
    title: 'Infrastructure',
    color: colors.emerald,
  },
};
