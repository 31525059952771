// icon, title, description, action
export const contacts = {
  email: {
    icon: 'email.svg',
    title: 'Email address',
    description: 'bonny.dev87@gmail.com',
    action: {
      title: 'Send email',
      href: 'mailto:bonny.dev87@gmail.com?subject=Contact%20Marco&body=Hi%20Marco%2C%0Awe%20are%20<company_name>.',
      requestCv:
        'mailto:bonny.dev87@gmail.com?subject=CV%20Request&body=Hi%20Marco%2C%0Awe%20are%20<company_name>%20and%20we%20would%20like%20to%20have%20your%20CV.',
    },
  },
  linkedin: {
    icon: 'linkedin.svg',
    title: 'Linkedin',
    description: 'marco-bonora-922211181',
    action: {
      title: 'Open link',
      href: 'https://www.linkedin.com/in/marco-bonora-922211181',
    },
  },
  github: {
    icon: 'github.svg',
    title: 'GitHub',
    description: 'mBonny87',
    action: {
      title: 'Open link',
      href: 'https://github.com/mBonny87',
    },
  },
};
