/* eslint-disable no-console */
import { useState } from 'react';

export const useLocalStorage = (key, defaultValue) => {
  const [storageValue, setStorageValue] = useState(defaultStorageCallback(key, defaultValue));

  const setValue = (newValue) => {
    try {
      localStorage.setItem(key, JSON.stringify(newValue));
    } catch (error) {
      console.log(error);
    }
    setStorageValue(newValue);
  };

  return [storageValue, setValue];
};

const defaultStorageCallback = (key, defaultValue) => {
  try {
    const item = localStorage.getItem(key);

    if (item) {
      return JSON.parse(item);
    }
    localStorage.setItem(key, JSON.stringify(defaultValue));
    return defaultValue;
  } catch (error) {
    return defaultValue;
  }
};
